import { GamePeriod } from '@arland-bmnext/api-data'
import { useState, useRef, useEffect } from 'react'
import { padNumber } from '../../util/number'

const GameTime = ({ currentPeriod, gameTime }: { currentPeriod: GamePeriod; gameTime: string }) => {
  const [gameTimeMinutes, setGameTimeMinutes] = useState(null)
  const gameTimeMinutesRef = useRef(gameTimeMinutes)
  const [gameTimeSeconds, setGameTimeSeconds] = useState(null)
  const gameTimeSecondsRef = useRef(gameTimeSeconds)

  useEffect(() => {
    const interval = setInterval(updateGameTime, 1000)
    return () => {
      clearInterval(interval)
    }
  }, [currentPeriod])

  useEffect(() => {
    gameTimeMinutesRef.current = gameTimeMinutes
  }, [gameTimeMinutes])

  useEffect(() => {
    gameTimeSecondsRef.current = gameTimeSeconds
  }, [gameTimeSeconds])

  useEffect(() => {
    if (gameTime) {
      parseGameTime(gameTime)
    }
  }, [gameTime])

  const parseGameTime = (gameTimeString: string) => {
    if (gameTimeString) {
      const split = gameTimeString.split(':')
      setGameTimeMinutes(parseInt(split[0]))
      setGameTimeSeconds(parseInt(split[1]))
    }
  }

  const updateGameTime = () => {
    if (gameTimeMinutesRef.current == null || gameTimeSecondsRef.current == null || currentPeriod?.period == -1) return
    if (gameTimeSecondsRef.current >= 59) {
      setGameTimeMinutes(gameTimeMinutesRef.current + 1)
      setGameTimeSeconds(0)
    } else {
      setGameTimeSeconds(gameTimeSecondsRef.current + 1)
    }
  }

  return (
    <>
      {padNumber(gameTimeMinutes ?? 0, 2)}:{padNumber(gameTimeSeconds ?? 0, 2)}
    </>
  )
}

export default GameTime
