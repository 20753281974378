import { Translate } from "next-translate"
import { alertService } from "../services/alert.service"

export const copyTextToClipboard = async (text: string, t: Translate) => {
  try {
    if ('clipboard' in navigator) {
      await navigator.clipboard.writeText(text)
    } else {
      document.execCommand('copy', true, text)
    }
    alertService.success(t('common:Common.copiedToClipboard'))
  }
  catch(err) {
    alertService.error(t('common:Common.copiedToClipboardError'))
    console.log('Copy to clipboard unsuccessfull', err)
  }
}