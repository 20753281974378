import LiveIconSvg from '../../public/live-icon.svg'

const LiveIcon = ({ color = 'text-live' }) => {
  return (
    <div className="live-icon flex flex-shrink-0 w-full h-full">
      <LiveIconSvg className={'live-icon-svg h-full ' + color} />
    </div>
  )
}

export default LiveIcon
