import {
  APayAccountCheckResultType,
  BetSlipState,
  CheckBetSlipErrorType,
  CheckBetSlipItemStatus,
  DocumentTypes,
  GivenBonusState,
  PasswordError,
  PaymentMessageCodes,
  PersonGender,
  PlaceBetSlipResultType,
  RedeemVoucherResultErrorType,
  ResetPasswordStatusCode,
  UserCreationResultErrors,
  UserDocumentRating,
  UserLogonResultState,
  UserSelfLockErrorCode,
} from '@arland-bmnext/api-data'
import { CashoutBetSlipResultType } from '@arland-bmnext/api-data/bets/cashoutBetSlipResultType'

export const EnumTranslator = (_t, _enum, _enumValue, _params = {}) => {
  const enumValueString = _enum[_enumValue]

  switch (_enum) {
    case UserLogonResultState:
      return _t('login:UserLogonResultState.' + enumValueString, _params)
    case UserCreationResultErrors:
      return _t('account:UserCreationResultErrors.' + enumValueString, _params)
    case BetSlipState:
      return _t('bets:BetSlipState.' + enumValueString, _params)
    case PasswordError:
      return _t('account:PasswordError.' + enumValueString, _params)
    case PlaceBetSlipResultType:
      return _t('bets:PlaceBetSlipResultType.' + enumValueString, _params)
    case CheckBetSlipErrorType:
      return _t('bets:CheckBetSlipErrorType.' + enumValueString, _params)
    case CheckBetSlipItemStatus:
      return _t('bets:CheckBetSlipItemStatus.' + enumValueString, _params)
    case ResetPasswordStatusCode:
      return _t('account:ResetPasswordStatusCode.' + enumValueString, _params)
    case UserSelfLockErrorCode:
      return _t('account:UserSelfLockErrorCode.' + enumValueString, _params)
    case RedeemVoucherResultErrorType:
      return _t('bonus:RedeemVoucherResultErrorType.' + enumValueString, _params)
    case GivenBonusState:
      return _t('bonus:GivenBonusState.' + enumValueString, _params)
    case UserDocumentRating:
      return _t('documents:UserDocumentRating.' + enumValueString, _params)
    case DocumentTypes:
      return _t('documents:DocumentTypes.' + enumValueString, _params)
    case CashoutBetSlipResultType:
      return _t('bets:CashoutBetSlipResultType.' + enumValueString, _params)
    case PersonGender:
      return _t('account:PersonGender.' + enumValueString, _params)
    case APayAccountCheckResultType:
      return _t('payment:APayAccountCheckResultType.' + enumValueString, _params)
    case PaymentMessageCodes:
      return _t('payment:PaymentMessageCodes.' + enumValueString, _params)
  }
}

export const EnumFlagTranslator = (_t, _enum, _flagValue, _params = {}) => {
  let message = [];
  const numberKeys = Object
    .keys(_enum)
    .filter((v) => !isNaN(Number(v)))

  for (const key of numberKeys) {
    if(_flagValue & Number(key)) message.push(EnumTranslator(_t, _enum, key, _params))
  }
  if (message.length != 0) return message.join(' ')
}
