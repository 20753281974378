const BetBuilderOdd = ({
  oddName,
  dotBackgroundColor,
  className = '',
}: {
  oddName: string
  dotBackgroundColor: string
  className?: string
}) => {
  const odds = oddName?.split('|')

  return (
    <div className={`bet-builder-odd flex flex-col ${className}`}>
      {odds?.map((odd, index) => {
        const split = odd?.split(' - ')
        const marketName = split[0]
        const outcomeName = split[1]

        return (
          <div className="bet-builder-odd-inner flex items-center relative pl-2" key={index}>
            <div className="bet-builder-odd-inner-indicator-wrapper absolute left-[3px]">
              <div
                className={`bet-builder-odd-inner-indicator h-3 w-3 rounded-full border-2 border-neutral ${dotBackgroundColor}`}
              ></div>
            </div>
            <div className="bet-builder-odd-inner-details flex flex-col grow border-l-[2px] border-neutral pl-3 py-1">
              <span className="bet-builder-odd-inner-details-market text-xs font-semibold">{marketName}</span>
              <span className="bet-builder-odd-inner-details-outcome text-xxs font-medium opacity-60">
                {outcomeName}
              </span>
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default BetBuilderOdd
